import React, { useEffect, useRef } from "react";
import portfolio from "../files/portfolio.pdf";

const IndexPage = () => {
  const anchorRef = useRef(null);

  useEffect(() => {
    anchorRef.current.click();
  });

  return (
    <main>
      <a href={portfolio} ref={anchorRef}></a>
    </main>
  );
};

export default IndexPage;
